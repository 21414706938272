import React, { useState, useEffect, Component } from "react";
import { useHistory, useParams, useLocation, Link } from "react-router-dom";
import { getEnv } from "../utils/Helper";
import { useDispatch, useSelector } from "react-redux";
import { SelectInput, ButtonInput } from "@emerson/dynamic-ui-dev";
import "./preferences.css";
import { RootState } from "../store/Store";
import {updatePreference, updateUserPreference, getUOMList, updateOrAddOptionsByPreferences} from '../store/actions/schemaActions'
import { use } from "i18next";

const Preferences = ({...props}) => {

  const dispatch = useDispatch();

  const systemUnitList = [{label:"All", value:"All"}, {label:"English", value:"English"}, {label:"Metric", value:"Metric"}];

  const { allOptions, englishOptions, metricOptions, metricValue, englishValue, allValue, defaultEnglish, defaultMetric} = useSelector((state: RootState) => state.schemaData.preferences);
  
  const userPreferencesValue = useSelector((state: RootState) => state.schemaData.userPreferencesValue);
  const loginState = useSelector((state: RootState) => state.schemaData.loginState);

  const userId = useSelector((state: RootState) => state.schemaData.userId);

  const [systemUnit, setSystemUnit] = useState("English");
  const [systemOptions, setSystemOptions] = useState(englishOptions);
  const [curUnit, setcurUnit] = useState(englishValue);

  const [userSelectUnit, setUserSelectUnit] = useState<any>({})
                                   

  // useEffect(() => {
  //   dispatch(getUOMList());
  // }, []);

  useEffect(()=>{
    let displayUnit = userPreferencesValue.displayUnit;
    if(displayUnit){
      setSystemUnit(displayUnit=="All" ? 'All' : displayUnit=="English" ? 'English' : 'Metric');
      setSystemOptions(displayUnit=="All" ? allOptions : displayUnit=="English" ? englishOptions: metricOptions);
      setcurUnit(displayUnit=="All" ? allValue : displayUnit=="English" ? englishValue : metricValue);
    }
  },[userPreferencesValue])
  

  const systemUnitChange = (obj:any, type:any, name:any, value:any)=>{
    setUserSelectUnit({});
    setSystemUnit(value);
    setSystemOptions(value=="All" ? allOptions : (value=="English" ? englishOptions: metricOptions));
    setcurUnit(value=="All" ? allValue : (value=="English" ? englishValue: metricValue))
  }

  
  const onClick = ()=>{
    let userSelectValue = Object.keys(userSelectUnit).filter((k)=> userSelectUnit[k]).reduce((res:any, key) => {
      res[key] = userSelectUnit[key]
      return res;
    }, {});
    let selectValue = {displayUnit: systemUnit, ...curUnit, ...userSelectValue};
    if(loginState){
      dispatch(updateUserPreference({userId: userId, preferences: selectValue}));
    } else {
      dispatch(updatePreference(selectValue)); 
      dispatch(updateOrAddOptionsByPreferences());
    }
  }

  return (
    <>
      <div className='ddl-grid ddl-grid--cols-12' style={{ margin: "2rem 0 0 2rem" }}>
        <div className='ddl-grid--col-span-3'>
          <SelectInput
            label="Display Unit"
            labelClass="app-content-label"
            name="critical"
            onChange={systemUnitChange}
            options={systemUnitList}
            placeholder="PLEASE SELECT"
            value={systemUnit}
          />
        </div>
        {
          Object.keys(englishOptions).map((item, index) => {
            return (
              <div className='ddl-grid--col-span-3'>
                <SelectInput
                  label={item}
                  labelClass="app-content-label"
                  name={item}
                  onChange={(obj:any, type:any, name:any, value:any) => {
                    setUserSelectUnit({...userSelectUnit, [name]: value})
                  }}
                  options={systemOptions[item]}
                  placeholder="PLEASE SELECT"
                  value={curUnit[item]}
                />
              </div>
            )
          })
        }
      </div >
      <div className='ddl-grid ddl-grid--cols-12' style={{ margin: "2rem 0 0 2rem" }}>
        <div className='ddl-grid--col-span-3'>
          <ButtonInput
            disabled={false}
            label={"Update"}
            onClick={onClick}
          />
        </div>
      </div>
    </>
  );
};

export default  React.memo(Preferences);
