import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { getEnv, lowerToUpper } from "./utils/Helper";
import { i18n as i18nOtherLib } from "@emerson/dynamic-ui-dev";

const base_url = getEnv("REACT_APP_API_BASE_URL");
const API_token = getEnv("REACT_APP_API_TOKEN");


i18n
.use(HttpApi)
.use(LanguageDetector)
.use(initReactI18next)
  .init({
    supportedLngs:["de-de","en-us", "es-es", "fr-fr", "it-it", "ja-jp", "ko-kr", "zh-cn", "pt-br",
    "de_de", "en_us",  "es_es", "fr_fr", "it_it", "ja_jp", "ko_kr", "zh_cn", "pt_br"
  ],
    detection: {
      // order and from where user language should be detected
      order: ['querystring','path', 'cookie', 'htmlTag', 'navigator', 'subdomain', 'localStorage'],
      lookupQuerystring: 'locale',
      lookupLocalStorage: 'i18nextLng',
      caches: ['cookie'],
      // convertDetectedLanguage: 'Iso15897',
      convertDetectedLanguage: (lng) => {
        return lng.replace("-","_")
      }
    },
    // lng: 'zh_cn',
    fallbackLng: "en_us", // use en_US if detected lng is not available
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
      // loadPath: `${base_url}/assembler/lang_translation/{{lng}}`,
      customHeaders: {
        authorization: API_token,
        "Content-Type": "application/json",
      },
    },
    debug: true,
  });

i18n.on("languageChanged", (lng) => {
  i18nOtherLib.changeLanguage(lowerToUpper(lng));
});

export default i18n;