const STORE_SCHEMA_SUCCESS = "STORE_SCHEMA_SUCCESS";
const STORE_SCHEMA_ERROR = "STORE_SCHEMA_ERROR";
const STORE_SCHEMA = "STORE_SCHEMA";

const STORE_CALCULATION_SUCCESS = "STORE_CALCULATION_SUCCESS";
const STORE_CALCULATION_ERROR = "STORE_CALCULATION_ERROR";
const STORE_CALCULATION = "STORE_CALCULATION";

const STORE_SIZING_RESULTS_SUCCESS = "STORE_SIZING_RESULTS_SUCCESS";
const STORE_SIZING_RESULTS_ERROR = "STORE_SIZING_RESULTS_ERROR";
const STORE_SIZING_RESULTS = "STORE_SIZING_RESULTS";

const STORE_TOP_ANCHOR = "STORE_TOP_ANCHOR";

const STORE_LEFT_ANCHOR_SUCCESS = "STORE_LEFT_ANCHOR_SUCCESS";
const STORE_LEFT_ANCHOR_ERROR = "STORE_LEFT_ANCHOR_ERROR";
const STORE_LEFT_ANCHOR = "STORE_LEFT_ANCHOR";

const STORE_RIGHT_ANCHOR_SUCCESS = "STORE_RIGHT_ANCHOR_SUCCESS";
const STORE_RIGHT_ANCHOR_ERROR = "STORE_RIGHT_ANCHOR_ERROR";
const STORE_RIGHT_ANCHOR = "STORE_RIGHT_ANCHOR";

const STORE_UPDATE_RIGHT_ANCHOR = "STORE_UPDATE_RIGHT_ANCHOR";
const STORE_UPDATE_LEFT_ANCHOR = "STORE_UPDATE_LEFT_ANCHOR";
const STORE_UPDATE_RIGHT_KEY_ANCHOR = "STORE_UPDATE_RIGHT_KEY_ANCHOR";
const STORE_UPDATE_LEFT_KEY_ANCHOR = "STORE_UPDATE_LEFT_KEY_ANCHOR";

const CLEAR_RIGHT_ANCHOR = "CLEAR_RIGHT_ANCHOR";

const STORE_PRODUCTION_LIST = "STORE_PRODUCTION_LIST";
const STORE_PRODUCTION_LIST_SUCCESS = "STORE_PRODUCTION_LIST_SUCCESS";
const STORE_PRODUCTION_LIST_ERROR = "STORE_PRODUCTION_LIST_ERROR";

const STORE_LANGUAGE_LIST = "STORE_LANGUAGE_LIST";
const STORE_LANGUAGE_LIST_SUCCESS = "STORE_LANGUAGE_LIST_SUCCESS";
const STORE_LANGUAGE_LIST_ERROR = "STORE_LANGUAGE_LIST_ERROR";
const UPDATE_CURRENT_NEID = "UPDATE_CURRENT_NEID";

const STORE_ACTIVE_SESSION = "STORE_ACTIVE_SESSION";
const STORE_ACTIVE_SESSION_SUCCESS = "STORE_ACTIVE_SESSION_SUCCESS";
const STORE_ACTIVE_SESSION_ERROR = "STORE_ACTIVE_SESSION_ERROR";

const DELETE_ACTIVE_SESSION = "DELETE_ACTIVE_SESSION";
const DELETE_ACTIVE_SESSION_SUCCESS = "DELETE_ACTIVE_SESSION_SUCCESS";
const DELETE_ACTIVE_SESSION_ERROR = "DELETE_ACTIVE_SESSION_ERROR";

const STORE_GET_ELEMENT_DATA = "STORE_GET_ELEMENT_DATA";

const RESET_RIGHT_ANCHOR_VALUES = "RESET_RIGHT_ANCHOR_VALUES";


const STORE_UOM_DATA = "STORE_UOM_DATA";
const GET_UOM_DATA_SUCCESS = "GET_UOM_DATA_SUCCESS";
const GET_UOM_DATA_ERROR = "GET_UOM_DATA_ERROR";

const PUT_UOM_DATA_SUCCESS =  "PUT_UOM_DATA_SUCCESS";
const PUT_UOM_DATA_ERROR =  "PUT_UOM_DATA_ERROR";
const UPDATE_USER_INFO = "UPDATE_USER_INFO";

const GET_ALL_SIZING = "GET_ALL_SIZING";

const GET_ALL_SIZING_SUCCESS = "GET_ALL_SIZING_SUCCESS";

const GET_ALL_SIZING_ERROR = "GET_ALL_SIZING_ERROR";

const STORE_UPDATE_TOP_ANCHOR = "STORE_UPDATE_TOP_ANCHOR";

const UPDATE_SIZING_INPUT = "UPDATE_SIZING_INPUT";

const UPDATE_LOADING = "UPDATE_LOADING";

const SET_REPORT_PAYLOAD = "SET_REPORT_PAYLOAD";

const GET_FETCH_SIZING = "GET_FETCH_SIZING";
 
const UPDATE_RIGHT_DATA = "UPDATE_RIGHT_DATA";

const UPDATE_USER_PREFERENCE_DATA = "UPDATE_USER_PREFERENCE_DATA";

const UPDATE_PREFERENCE_DATA = "UPDATE_PREFERENCE_DATA";

const UPDATE_BU_CODE = "UPDATE_BU_CODE";
const UPDATE_LOGIN_STATE = "UPDATE_LOGIN_STATE";
const GET_USER_PREFERENCE = "GET_USER_PREFERENCE";
const UPDATE_USER_ID = "UPDATE_USER_ID";
const UPDATE_EDIT_SIZINGID = "UPDATE_EDIT_SIZINGID";
const UPDATE_OPTIONS_BY_PREFERECES = "UPDATE_OPTIONS_BY_PREFERECES";

const STORE_DEFAULT_RIGHT_ANCHOR = "STORE_DEFAULT_RIGHT_ANCHOR";




const Constant = {
  STORE_SCHEMA_SUCCESS,
  STORE_SCHEMA_ERROR,
  STORE_SCHEMA,

  STORE_CALCULATION_SUCCESS,
  STORE_CALCULATION_ERROR,
  STORE_CALCULATION,

  STORE_SIZING_RESULTS_SUCCESS,
  STORE_SIZING_RESULTS_ERROR,
  STORE_SIZING_RESULTS,

  STORE_TOP_ANCHOR,

  STORE_LEFT_ANCHOR_SUCCESS,
  STORE_LEFT_ANCHOR_ERROR,
  STORE_LEFT_ANCHOR,

  STORE_RIGHT_ANCHOR_SUCCESS,
  STORE_RIGHT_ANCHOR_ERROR,
  STORE_RIGHT_ANCHOR,

  STORE_UPDATE_LEFT_ANCHOR,
  STORE_UPDATE_RIGHT_ANCHOR,
  STORE_UPDATE_RIGHT_KEY_ANCHOR,
  STORE_UPDATE_LEFT_KEY_ANCHOR,

  CLEAR_RIGHT_ANCHOR,

  STORE_PRODUCTION_LIST,
  STORE_PRODUCTION_LIST_SUCCESS,
  STORE_PRODUCTION_LIST_ERROR,

  STORE_LANGUAGE_LIST,
  STORE_LANGUAGE_LIST_SUCCESS,
  STORE_LANGUAGE_LIST_ERROR,

  UPDATE_CURRENT_NEID,

  STORE_ACTIVE_SESSION,
  STORE_ACTIVE_SESSION_SUCCESS,
  STORE_ACTIVE_SESSION_ERROR,

  DELETE_ACTIVE_SESSION,
  DELETE_ACTIVE_SESSION_SUCCESS,
  DELETE_ACTIVE_SESSION_ERROR,

  STORE_GET_ELEMENT_DATA,

  RESET_RIGHT_ANCHOR_VALUES,

  STORE_UOM_DATA,
  GET_UOM_DATA_SUCCESS,
  GET_UOM_DATA_ERROR,
  UPDATE_USER_PREFERENCE_DATA,
  UPDATE_PREFERENCE_DATA,
  PUT_UOM_DATA_SUCCESS,
  PUT_UOM_DATA_ERROR,
  UPDATE_USER_INFO,
  GET_ALL_SIZING,
  GET_ALL_SIZING_SUCCESS,
  GET_ALL_SIZING_ERROR,
  STORE_UPDATE_TOP_ANCHOR,
  UPDATE_SIZING_INPUT,
  UPDATE_LOADING,
  SET_REPORT_PAYLOAD,
  GET_FETCH_SIZING,
  UPDATE_RIGHT_DATA,
  UPDATE_BU_CODE,
  UPDATE_LOGIN_STATE,
  GET_USER_PREFERENCE,
  UPDATE_USER_ID,
  UPDATE_EDIT_SIZINGID,
  UPDATE_OPTIONS_BY_PREFERECES,
  STORE_DEFAULT_RIGHT_ANCHOR
};

export default Constant;
