import { all } from "redux-saga/effects";

import {
  storeSchema,
  storeCalculation,
  storeSizingResults,
  storeLeftAnchor,
  storeRightAnchor,
  getProductionList,
  getLanguageList,
  storeActiveSession,
  deleteActiveSession,
  getUOMData,
  storeAllSizing,
  getFetchSizing,
  updateUserPreference,
  getUserPreference
} from "./saga/schemaSaga";

function* rootSaga() {
  yield all([
    storeSchema(),
    storeCalculation(),
    storeSizingResults(),
    storeLeftAnchor(),
    storeRightAnchor(),
    getProductionList(),
    getLanguageList(),
    storeActiveSession(),
    getFetchSizing(),
    getUOMData(),
    deleteActiveSession(),
    updateUserPreference(),
    storeAllSizing(),
    getUserPreference()
  ]);
}

export default rootSaga;
