import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation, Link } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { useDispatch, useSelector } from "react-redux";
import store, { RootState } from "../../store/Store";
import { CustomTop, ButtonInput } from "@emerson/dynamic-ui-dev";
import { useTranslation } from 'react-i18next';
import { saveSizingAPI, getUserPreferenceAPI} from "../../api/home";
import { updateLoading, updateTopData, getUOMList, getAllSizing, getLeftAnchorData, updateBuCode, updatePreference, getUserPreference, updateSizingId } from "../../store/actions/schemaActions";
import { getEnv, getTranslateCode, lowerToUpper } from "../../utils/Helper";
import Preferences from '../../components/Preferences';
import MySizing from '../../components/MySizing';
import Report from "../../components/Report";
import Home from './Home';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";


const HomePage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const bu_code = useSelector(
    (state: RootState) => state.schemaData.bu_code
  );
  const topAnchor = useSelector(
    (state: RootState) => state.schemaData.top_anchor
  );

  const sizingInputs = useSelector(
    (state: RootState) => state.schemaData.sizingInputs
  );
  
  const leftAnchor = useSelector(
    (state: RootState) => state.schemaData.left_anchor
  );

  const rightAnchor = useSelector(
    (state: RootState) => state.schemaData.right_anchor
  );

  const defaultNeId = useSelector(
    (state: RootState) => state.schemaData.defaultNeId
  );

  const currentSizingId = useSelector(
    (state: RootState) => state.schemaData.currentSizingId
  )

  const loginState = useSelector((state: RootState) => state.schemaData.loginState);

  const userInfo = useSelector((state: RootState) => state.schemaData.userInfo);

  const [currentPage, setCurrentPage] = useState("home");
  const [open, setOpen] = useState(false);
  const [sizingId, setSizingId] = useState("");

  const userId = useSelector((state: RootState) => state.schemaData.userId);
  
 
  useEffect(() => {  
    let params = new URLSearchParams(window.location.search.substring(1));
    let currentBuCode = params.get("bu_code");
    if(currentBuCode){
      currentBuCode = currentBuCode.toUpperCase();
      dispatch(updateBuCode(currentBuCode));
      dispatch(getLeftAnchorData({
          business_unit_code: currentBuCode,
          p_traffic_source_code: 'NORMAL_ENTRY',
          lang: lowerToUpper(i18n.language)
      }))
      if(currentBuCode == "SPENCE"){
        dispatch(getUOMList());
      }
      
    } else {
      dispatch(getLeftAnchorData({
          business_unit_code: bu_code,
          p_traffic_source_code: 'NORMAL_ENTRY',
          lang: lowerToUpper(i18n.language)
      }));
    }
  }, []);

  useEffect(() => {
      if(JSON.stringify(userInfo) !== '{}'){
        if(bu_code == "SPENCE"){
         console.log("userInfo", userInfo);
         dispatch(getUserPreference({email: userInfo.email}));
      }
    }
  }, [userInfo]);

  const handleMenuChange = async (name: any, obj: any) => {
    if (name === "hSpence_t_customMenu") {
        if(obj.url){
            if(obj.value.includes("sizing")){
                setCurrentPage('home')
            } else {
                // mySizing, sizing, preferences
                setCurrentPage(obj.value)
            }
            let topData = JSON.parse(JSON.stringify(topAnchor));  
            topData.forEach((element: any) => {
              if (element.name === "hSpence_t_customMenu") {
                element.value = obj.value;
              }
            });  
            dispatch(updateTopData(topData));
        }
        if (obj.value === 'Save') {
          dispatch(updateLoading(true))
          // saveSizingAPI sizingId is edit
          //如果在第四页点击save，需要把转换到第三页，并把第四页的数据清除
          
          let currentLeftAnchor = JSON.parse(JSON.stringify(leftAnchor));
          let currentRightAnchor = JSON.parse(JSON.stringify(rightAnchor));

          let currentIndex = currentRightAnchor.length - 1;
          let currentNeId = currentRightAnchor[currentIndex].id;
          if(currentIndex == currentLeftAnchor.length - 1){
            currentNeId = currentRightAnchor[currentIndex - 1].id;
            currentRightAnchor.splice(currentIndex, 1);
            currentLeftAnchor.forEach((item: any, index: number) => {
              if (index == currentIndex - 1) {
                item.selected = true;
              } else {
                item.selected = false;
              }
              if (index <= currentIndex - 1) {
                item.enabled = true;
              }
            })
          }
          // remove the options of the currentRightAnchor
          //如果是编辑，需要把当前的sizingId传过去，否则传空
          const optionsTypeArr = ["TILE_THUMBNAIL", "CUSTOM_BUTTON_GROUP", "SINGLE_SELECT", "RADIO_INPUT"];
         

          currentRightAnchor.forEach((item: any) => {
            item.fields.forEach((field: any) => {
              field.attribute_code_value = {};
              // if (optionsTypeArr.includes(field.type)) {
              //   field.options = [];
              //   field.initOptions = [];
              // }
              if(field.type == "TABLE_INPUT"){
                field.options.forEach((option: any) => {
                  option.forEach((item: any) => {
                    if(item.type == "SINGLE_SELECT" && item.name !="fillingHeadAvailable" && !(item.dynamicVariable == false)){
                      item.options = [];
                    }
                  });
                });
                field.initOptions = []
              }
            });
          });

          if(currentSizingId){
            let response: any = await saveSizingAPI({ sizingId: currentSizingId, userId: userId, defaultNeId: currentNeId, ...sizingInputs, leftAnchor: currentLeftAnchor, rightAnchor: currentRightAnchor});
            if (response.status === 200) {
              dispatch(getAllSizing({userId: userId}));
              dispatch(updateLoading(false))
              setOpen(true);
              setSizingId(response.data.data.sizingId);
            }

          } else {
            let response: any = await saveSizingAPI({ userId: userId, defaultNeId: currentNeId, ...sizingInputs, leftAnchor: currentLeftAnchor, rightAnchor: currentRightAnchor});
            if (response.status === 200) {
              dispatch(getAllSizing({userId: userId}));
              dispatch(updateLoading(false))
              setOpen(true);
              setSizingId(response.data.data.sizingId);
            }
          }
          
        }
      }

  };
  
  const handleClose = (type: string, label: any) => {
    setOpen(false);
    // Should clear the form
  };

  const getConent = (item: any) => {
    switch (item) {
        case  "home":
          return <Home setCurrentPage={setCurrentPage} /> 
        case "preferences":
          return <Preferences />
        case "mySizing":
          return <MySizing setCurrentPage={setCurrentPage} />
        case "report":
          return <Report setCurrentPage={setCurrentPage} />
    }
  }

  const hiddenSave = () => {
    let topData = JSON.parse(JSON.stringify(topAnchor));
    topData.forEach((element: any) => {
      if (element.name === "hSpence_t_customMenu") {
        element.options = element.options.filter((item: any) => item.url);
      }
    });
    return topData;
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Sizing Saved"}</DialogTitle>
        <DialogContent>
          {currentSizingId ? <DialogContentText id="alert-dialog-description">
            Your sizing has been updated successfully with sizing id:
            <strong>{sizingId}</strong>
          </DialogContentText> :  <DialogContentText id="alert-dialog-description">
            Your sizing has been saved successfully with sizing id:
            <strong>{sizingId}</strong>
          </DialogContentText>}
        </DialogContent>
        <DialogActions>
          <ButtonInput onClick={handleClose} label={"Close"} />
        </DialogActions>
      </Dialog>
      <div
        id="customTopId"
        className="ddl-grid ddl-grid--cols-12"
        style={{
          position: "sticky",
          top: "84px",
          backgroundColor: "white",
          zIndex: 1,
        }}
      >
        <div className="ddl-grid--col-span-12">
          {topAnchor && (
            <CustomTop
              data={currentPage == "home" ? topAnchor : hiddenSave()}
              loginState={loginState}
              onChange={handleMenuChange}
            />
          )}
        </div>
      </div>
      {getConent(currentPage)}
    </div>
  );
};

export default HomePage;
