import React, { useEffect, useState } from "react";
import { Switch, Route, BrowserRouter, useHistory } from "react-router-dom";
import {
  SecureRoute,
  Security,
  LoginCallback,
  useOktaAuth,
} from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import Home from "./containers/Home/HomePage";
import Header from "./components/Header";
import Loader from "./components/Loader";
import config from "./utils/okta.config";
import { getEnv } from "./utils/Helper";
import store from "./store/Store";
import { Provider } from "react-redux";
import { useTranslation } from "react-i18next";
import { getActiveSession } from "./store/actions/schemaActions";
import Preferences from "./components/Preferences";
import Mysizing from "./components/MySizing";
import Report from "./components/Report";

const oktaAuth = new OktaAuth(config.oidc);

export default function App() {
    const { t, i18n } = useTranslation();

    const history = useHistory();

    const [active, setActive] = useState(false);

    let showHeader = !new URLSearchParams(
      window.location.search.substring(1)
    ).get("bu_code");

    const restoreOriginalUri = async (oktaAuth: any, originalUri: any) => {
      history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
    };
    
    useEffect(() => {
      // <!-- Google Tag Manager -->
      function googleTagManager(w:any, d:any, s:any, l:any, i:any) {
        w[l] = w[l] || []; 
        w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
           dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
           // variable 
          var v = d.createElement(s);
          v.async = true;
          //external script
          v.src = '/googleTagManager.js';
          f.parentNode.insertBefore(v, f);
      }
      googleTagManager(window, document, 'script', 'dataLayer', 'GTM-NWMNH6V9');
      // <!-- End Google Tag Manager -->
    }, []);

    return (
        <Provider store={store}>
            <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
                <Header showHeader={showHeader} />
                <Loader />
                <Switch>
                    <div>
                        <Route path={`${getEnv("PUBLIC_URL")}/`}  component={Home}  exact />
                        <Route path={`${getEnv("PUBLIC_URL")}/level`} component={Home}  />
                        <Route path={`${getEnv("PUBLIC_URL")}/:locale/catalog/level`} component={Home} />
                        <Route path={`${getEnv("PUBLIC_URL")}/login/callback`} component={LoginCallback}  />
                        <Route path={`${getEnv("PUBLIC_URL")}/integrations/gos/welcome_page_spence`} component={Home} />
                        <Route path={`${getEnv("PUBLIC_URL")}/preferences`} >
                           <Preferences />
                        </Route>
                        <Route path={`${getEnv("PUBLIC_URL")}/mySizing`} >
                           <Mysizing />
                        </Route>
                        <Route path={`${getEnv("PUBLIC_URL")}/report`} >
                           <Report />
                        </Route>
                    </div>
                </Switch>
            </Security>
        </Provider>
    )
}
