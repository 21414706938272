import React, { useState, useEffect } from 'react';
import { useHistory, useParams, useLocation, Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import { ButtonInput, TextInput } from '@emerson/dynamic-ui-dev'
import { useDispatch, useSelector } from 'react-redux';
import store, { RootState } from '../store/Store';
import './Report.css';
import { fetchReportsApi } from '../api/home';
import html2pdf from 'html2pdf.js';


const  Report = ({...props}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const reportPayload = useSelector((state: RootState) => state.schemaData.reportPayload);
  const sizingInputs = useSelector((state: RootState) => state.schemaData.sizingInputs);
 
  interface report {
    htmlString?: any,
    excel?: string,
    pdf?: string
  }
  
  const [formObj, setFormObj] = useState({ project: '', location: '', tag: ''});
  const [reportShow, setReportShow] = useState(false);
  const [reports, setReports] = useState<report>({});
  
  const handleChange = (e: any, type: any, name: any, value: any) => {
    let formCopy = JSON.parse(JSON.stringify(formObj));
    setFormObj({...formCopy, [name]: value});
  }

  const handleClick = () => {
    const params = {
      input: {...sizingInputs,...formObj},
      data: reportPayload
    }
    fetchReportsApi(params).then((response:any) => {
      if(response.status == 200){
        setReports(response.data);
        setReportShow(true);
      }
      
    }).catch((error) => {
      console.log(error)
    })
  }

  const downloadExcel = () => {
    // download base64 string as excel file
    const link = document.createElement('a');
    link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + reports.excel;
    link.download = `report.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

const downloadPDF = () => {
    const element = document.getElementsByTagName('table')[0];
    const opt = {
        margin: 0.5,
        filename: 'report.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 1 },
        jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
    };
    html2pdf().set(opt).from(element).save();
}

  return (
    <>
    {!reportShow  && <>
      <Grid container sx={{mt: 4}}>
          <Grid item xs={6} sx={{px: 2}}>
              <TextInput label="Project"  name="project" value={formObj.project} onChange={handleChange}/>
          </Grid>
          <Grid item sm={6} sx={{px: 2}}>
              <TextInput label="Location" name="location" value={formObj.location} onChange={handleChange} />
          </Grid>                
      </Grid>
      <Grid container sx={{mt: 4}}>
          <Grid item sm={6} sx={{px: 2}}>
              <TextInput label="Tag" name="tag" value={formObj.tag} onChange={handleChange} />
          </Grid>
      </Grid>
      <Grid container sx={{mt: 4}}>
          <Grid item sm={6} sx={{px: 2}}>
              <ButtonInput label="Generate Report" onClick={handleClick} />
          </Grid>
      </Grid>
    </>}
    {reportShow && <>
      <Grid container >
          <Grid item xs={1}></Grid>
          <Grid item xs={11}>
              <h1>Reports</h1>
          </Grid>
      </Grid >
      <div className='btnExportGroup'>
          <ButtonInput label={'Back'} onClick={() => {    
              props.setCurrentPage('home');
          }} />
          <ButtonInput label={'PDF'} onClick={() => { downloadPDF() }} />
          <ButtonInput label={'Excel'} onClick={() => { downloadExcel() }} />
      </div>
      <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
              <div dangerouslySetInnerHTML={{ __html: reports.htmlString }}></div>
          </Grid>
          <Grid item xs={1}></Grid>
      </Grid>
    </>}
  </>
  );
};

export default Report;

