import React, { useState, useEffect } from 'react';
import { useHistory, useParams, useLocation, Link } from 'react-router-dom';
import { getEnv,lowerToUpper } from "../utils/Helper";
import { Grid } from '@mui/material';
import { CustomTable } from '@emerson/dynamic-ui-dev'
import { useDispatch, useSelector } from 'react-redux';
import { getAllSizing, getFetchSizing, updateCurrentId, updateRightData, updateLeftAnchorData, updatePreference, updateSizingId, updateSizingInput, updateOrAddOptionsByPreferences, updateTopData} from "../store/actions/schemaActions";
import store, { RootState } from '../store/Store';
import './MySizing.css';
import { useTranslation } from 'react-i18next';



const SavedParams = ({ data }: any) => {
  const [toggle, setToggle] = React.useState(false);
  return (<>
    {Object.keys(data).map((k, i) => {
      let value = data[k];
      if(typeof value === 'string') {
        value = data[k];
      }
      if(typeof value === 'object') {
        let valueC = "";
        Object.values(value).map((v: any) => {
          if(isNaN(v) && typeof v === 'string'){
            valueC = valueC + " " + v.split(".")[v.split(".").length -1];
          } else {
            valueC = valueC + "" + v;
          }
        });
        value = valueC;
      }
      return (<div key={`${k}-${i}`} className={`${(i > 2 && !toggle) && 'hide'}`}><strong>{k.split("_")[k.split("_").length -1]}:</strong> {value}</div>)
    })
  }
    {Object.keys(data).length > 3 && <span className="more-less-toggle" onClick={() => setToggle(!toggle)}>{toggle ? 'less' : 'more'}</span>}
  </>)
}


const  MySizing = ({...props}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const userInfo = useSelector((state: RootState) => state.schemaData.userInfo);
  const allSizing = useSelector((state: RootState) => state.schemaData.allSizing);
  const left_anchor = useSelector((state: RootState) => state.schemaData.left_anchor);
  const topAnchor = useSelector((state: RootState) => state.schemaData.top_anchor);
  
  const columns = [
    {
      name: 'sizingId',
      label: 'Sizing ID'
    },
    {
      name: 'hSpence_r1_ValveCategory',
      label: 'Valve Category'
    },
    {
      name: 'hSpence_r2_1_FluidType',
      label: 'Fluid Type'
    },
    {
      name: 'hSpence_r2_6_Technology',
      label: 'Technology'
    },
    {
      name: 'hSpence_r2_7_Application',
      label: 'Application'
    },
    {
      name: 'savedParameters',
      label: 'Saved Parameters',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          if (value) {
            const valueJson = JSON.parse(value);
            return <SavedParams data={valueJson} />
          }
          return '';
        }
      },
    },
    {
      name: 'action',
      label: 'Actions',
      options: {
        sort: false,
        filter: false,
        download: false,
        print: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <>
            <button
              onClick={() => {
                let currentRow = tableMeta.tableData.find((item: any, index: number)=> tableMeta.rowIndex ==  index);
                dispatch(updateSizingId(currentRow.sizingId));
                dispatch(updateLeftAnchorData(currentRow.leftAnchor))
                dispatch(updateRightData(currentRow.rightAnchor))
                dispatch(updateCurrentId(currentRow.defaultNeId))
                dispatch(updatePreference(currentRow.userDetails))
                dispatch(updateSizingInput(currentRow.sizingInputs));
                dispatch(updateOrAddOptionsByPreferences('edit'));
                props.setCurrentPage('home');
                //navigate to home page
                let topData = JSON.parse(JSON.stringify(topAnchor));
                console.log("topDatabefore", topData);  
                topData.forEach((element: any) => {
                  if (element.name === "hSpence_t_customMenu") {
                    element.value = "sizing";
                  }
                });
                console.log("topDataafter", topData);  
                dispatch(updateTopData(topData));
                //navigate to home page
              }}
            >
              View
            </button>
            
             {/* <button
             style={{marginLeft: "8px"}}
             onClick={() => {
               console.log("delete")
             }}
           >
             Delete
           </button> */}
           </>
          )
        }
      },
      type: 'action'
    },
  ];
  // useEffect(()=>{
  //   dispatch(getAllSizing({userId: 35}));
  // },[])
    
  return (
    <>
      <Grid container px={'2rem'} my={'1rem'}>
        <Grid item xs={12}>
          <CustomTable
            subLabel={"My Sizing"}
            data={allSizing}
            columns={columns}
            options={{
              selectableRows: false
            }}
          />
        </Grid>
      </Grid>
    </>   
  );
};

export default MySizing;

